var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main-top"},[_c('img',{attrs:{"src":require("@/assets/image/arrow-left.png")},on:{"click":_vm.goBack}}),_c('span',[_vm._v("兑换记录")])]),_c('div',{staticClass:"content",on:{"scroll":_vm.handleScroll}},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"content-item"},[_c('div',{staticClass:"content-item-left"},[_c('div',{staticClass:"orderId"},[_vm._v(_vm._s(item.orderId))]),_c('div',{staticClass:"giftName"},[_vm._v(_vm._s(item.giftName))])]),_c('div',{staticClass:"content-item-right"},[_c('div',{staticClass:"displayPoints"},[_vm._v("- "+_vm._s(item.displayPoints)+" 金币")]),_c('div',{staticClass:"confirmDate"},[_vm._v(_vm._s(item.confirmDate))]),_c('div',{class:[
            'status',
            {
              status1: item.status === '审核通过'
            },
            {
              status2: item.status === '等待审核'
            },
            {
              status3: item.status === '订单取消'
            },
            {
              status4: item.status === '订单完成'
            },
            {
              status5: item.status === '等待确认'
            },
            {
              status6: item.status === '审核拒绝'
            }
          ]},[_c('span'),_vm._v(_vm._s(item.status)+" ")])])])}),(_vm.list.length > 0)?_c('div',{staticClass:"isBottom"},[_vm._v(" "+_vm._s(_vm.isBottom ? "我也是有底线的" : "触底刷新更多~")+" ")]):_vm._e(),(_vm.list.length === 0)?_c('van-empty',{attrs:{"description":"暂无数据"}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }