<template>
  <div class="main">
    <div class="main-top">
      <img @click="goBack" src="@/assets/image/arrow-left.png" />
      <span>兑换记录</span>
    </div>
    <div class="content" @scroll="handleScroll">
      <div class="content-item" v-for="(item, index) in list" :key="index">
        <div class="content-item-left">
          <div class="orderId">{{ item.orderId }}</div>
          <div class="giftName">{{ item.giftName }}</div>
        </div>
        <div class="content-item-right">
          <div class="displayPoints">- {{ item.displayPoints }} 金币</div>
          <div class="confirmDate">{{ item.confirmDate }}</div>
          <div
            :class="[
              'status',
              {
                status1: item.status === '审核通过'
              },
              {
                status2: item.status === '等待审核'
              },
              {
                status3: item.status === '订单取消'
              },
              {
                status4: item.status === '订单完成'
              },
              {
                status5: item.status === '等待确认'
              },
              {
                status6: item.status === '审核拒绝'
              }
            ]"
          >
            <span></span>{{ item.status }}
          </div>
        </div>
      </div>

      <div class="isBottom" v-if="list.length > 0">
        {{ isBottom ? "我也是有底线的" : "触底刷新更多~" }}
      </div>
      <van-empty v-if="list.length === 0" description="暂无数据" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      flag: false,
      page: 1,
      isBottom: false
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getList() {
      await this.$http
        .get(`/api/v1/Accounts/${this.userid}/Orders`, {
          params: {
            page: this.page,
            size: 20,
            id: this.userid
          }
        })
        .then(res => {
          const response = JSON.parse(res.request.response);
          if (res.status === 200 && response.data) {
            if (response.data.list && response.data.list.length !== 0) {
              this.list = this.list.concat(response.data.list);
              console.log("this.list", this.list);
              this.flag = true;
              this.page++;
            } else {
              this.flag = false;
              this.isBottom = true;
            }
            if (response.data.list.length < 20) {
              this.flag = false;
              this.isBottom = true;
            }
          } else {
            if (res.statusText) {
              this.$toast(res.statusText);
            }
          }
        });
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当滚动到底部时，距离顶部+自身高度 >= 总高度
      if (scrollTop + clientHeight >= scrollHeight) {
        // 触发刷新操作
        console.log("触底刷新");
        if (this.flag) {
          this.flag = false;
          console.log("触底刷新");
          this.getList();
        }
      }
    }
  },
  async created() {
    if (localStorage.getItem("userid")) {
      this.userid = localStorage.getItem("userid");
    } else {
      this.$router.push("/mobile");
    }
    await this.getList();
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 40px);
  width: 100vw;
  background-color: #f8f8fa;
  position: relative;
  padding-top: 40px;
  .main-top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #232323;
    font-size: 16px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    background-color: #f8f8fa;
    z-index: 3;
    img {
      width: 20px;
      position: absolute;
      left: 0;
      margin-left: 15px;
    }
  }

  .content {
    height: calc(100vh - 40px);
    overflow: auto;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    .content-item {
      margin-bottom: 10px;
      width: 100%;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 4px;
      padding: 20px 10px 15px 10px;
      display: flex;
      justify-content: space-between;
      .content-item-left {
        .orderId {
          color: #232323;
          font-size: 14px;
        }
        .giftName {
          color: #232323;
          font-size: 14px;
          font-weight: bold;
          margin-top: 15px;
        }
      }
      .content-item-right {
        text-align: right;
        flex-shrink: 0;
        margin-left: 10px;
        .displayPoints {
          color: #232323;
          font-size: 16px;
          font-weight: bolder;
        }
        .confirmDate {
          color: #999999;
          font-size: 12px;
          margin-top: 9px;
        }
        .status {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 12px;
          margin-top: 10px;
          span {
            width: 4px;
            height: 4px;
            background: #37d807;
            margin-right: 4px;
          }
        }
        .status1 {
          color: #37d807;
          span {
            background-color: #37d807;
          }
        }
        .status2 {
          color: #00a6f2;
          span {
            background-color: #00a6f2;
          }
        }
        .status3 {
          color: #ffa200;
          span {
            background-color: #ffa200;
          }
        }
        .status4 {
          color: #4f7eff;
          span {
            background-color: #4f7eff;
          }
        }
        .status5 {
          color: #00cec8;
          span {
            background-color: #00cec8;
          }
        }
        .status6 {
          color: #ff3636;
          span {
            background-color: #ff3636;
          }
        }
      }
    }
  }
}
.isBottom {
  text-align: center;
  margin: 20px 0;
}

.zhezhao {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
}

// .jl-box {
//   position: fixed;
//   z-index: 1000001;
//   width: 69.8vw;
//   height: 80vw;
//   top: 40%;
//   left: 50%;
//   transform: translateX(-50%) translateY(-50%);
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   .jl-box-card {
//     width: 100%;
//     height: 100%;
//   }

//   .identifier {
//     position: absolute;
//     top: 29vw;
//     left: 50%;
//     transform: translateX(-50%);
//     text-align: center;
//     color: #c23d11;
//     font-size: 3.2vw;
//   }

//   // background-color: #fff;
//   .bgCard1 {
//     width: 100%;
//     height: 100%;
//   }

//   .close {
//     position: absolute;
//     right: -5.2vw;
//     top: 4.6vw;
//     width: 5.2vw;
//     height: 5.2vw;
//     cursor: pointer;
//   }

//   .bgicon-box {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: flex-end;
//     position: absolute;
//     top: 36vw;

//     .bgIcon1-box {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       color: #c23d11;
//       font-size: 3.6vw;
//       font-weight: bolder;
//     }

//     .bgIcon3-box {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       color: #c23d11;
//       font-size: 3.6vw;
//       font-weight: bolder;
//       position: relative;

//       .X2-box {
//         position: absolute;
//         top: -1.6vw;
//         right: -3vw;
//         width: 5.8vw;
//         height: 3.2vw;
//         background: linear-gradient(311deg, #ff6a55 0%, #ffb330 100%);
//         border-radius: 1.6vw 1.6vw 1.6vw 0px;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         .X2 {
//           width: 3.2vw;
//         }
//       }
//     }

//     .bgIcon1 {
//       width: 10.8vw;
//       height: 10.8vw;
//     }

//     .bgicon2 {
//       width: 9vw;
//       height: 8.4vw;
//       margin: 0 1.2vw 6vw 1.2vw;
//     }

//     .bgicon3 {
//       width: 16.2vw;
//       height: 13.8vw;
//     }
//   }

//   .bgicon-box2 {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     position: absolute;
//     top: 36vw;

//     .bgCard2-bg {
//       width: 16vw;
//       margin-top: 2.8vw;
//     }
//   }

//   .bgicon-box3 {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     top: 36vw;

//     .bgCard3-bg {
//       width: 20vw;
//       margin-top: 0px;
//     }

//     .bgCard3-name {
//       display: flex;
//       justify-content: center;
//       color: #c23d11;
//       font-size: 3.2vw;
//       margin-top: 3vw;

//       .bgCard3-name-left {
//         margin-right: 3.8vw;
//       }
//     }
//   }

//   .btn-have {
//     width: 30vw;
//     height: 8.4vw;
//     line-height: 8.4vw;
//     background: linear-gradient(270deg, #fcbc3c 0%, #ff5f15 100%);
//     box-shadow: inset 0.2vw 0.2vw 1vw 0px rgba(255, 255, 255, 0.4),
//       0px 0.8vw 1.4vw 0px #ffe185;
//     border-radius: 4.2vw;
//     text-align: center;
//     color: #fff;
//     font-size: 3.2vw;
//     position: absolute;
//     bottom: 12vw;
//     left: 50%;
//     transform: translateX(-50%);
//     cursor: pointer;
//   }

//   .jl-tips {
//     position: absolute;
//     bottom: 0px;
//     left: 50%;
//     transform: translateX(-50%);
//     text-align: center;
//     color: #d9d9d9;
//     font-size: 2.8vw;
//   }
//   .jl-tips2 {
//     bottom: -4vw;
//   }
// }
.jl-box {
  position: fixed;
  z-index: 1000001;
  width: 349px;
  height: 400px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .jl-box-card {
    width: 100%;
    height: 100%;
  }

  .identifier {
    position: absolute;
    top: 145px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #c23d11;
    font-size: 16px;
  }

  // background-color: #fff;
  .bgCard1 {
    width: 100%;
    height: 100%;
  }

  .close {
    position: absolute;
    right: -26px;
    top: 23px;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  .bgicon-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: 180px;

    .bgIcon1-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #c23d11;
      font-size: 18px;
      font-weight: bolder;
    }

    .bgIcon3-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #c23d11;
      font-size: 18px;
      font-weight: bolder;
      position: relative;

      .X2-box {
        position: absolute;
        top: -8px;
        right: -15px;
        width: 29px;
        height: 16px;
        background: linear-gradient(311deg, #ff6a55 0%, #ffb330 100%);
        border-radius: 8px 8px 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        .X2 {
          width: 16px;
        }
      }
    }

    .bgIcon1 {
      width: 54px;
      height: 54px;
    }

    .bgicon2 {
      width: 45px;
      height: 42px;
      margin: 0 6px 30px 6px;
    }

    .bgicon3 {
      width: 81px;
      height: 69px;
    }
  }

  .bgicon-box2 {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 180px;

    .bgCard2-bg {
      width: 80px;
      margin-top: 14px;
    }
  }

  .bgicon-box3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 180px;

    .bgCard3-bg {
      width: 100px;
      margin-top: 0px;
    }

    .bgCard3-name {
      display: flex;
      justify-content: center;
      color: #c23d11;
      font-size: 16px;
      margin-top: 15px;

      .bgCard3-name-left {
        margin-right: 19px;
      }
    }
  }

  .btn-have {
    width: 150px;
    height: 42px;
    line-height: 42px;
    background: linear-gradient(270deg, #fcbc3c 0%, #ff5f15 100%);
    box-shadow: inset 1px 1px 5px 0px rgba(255, 255, 255, 0.4),
      0px 4px 7px 0px #ffe185;
    border-radius: 21px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .jl-tips {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #d9d9d9;
    font-size: 14px;
  }
  .jl-tips2 {
    bottom: -20px;
  }
}
</style>
